
import {Component, Mixins, Watch} from "vue-property-decorator";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import {ITask, Task} from "@/plugins/uyap-plugin/store/modules/GorevYoneticisiTypes";
import {TaskTuru} from "@/plugins/uyap-plugin/enum/TaskTuru";
import FormDialog from "@/components/dialogs/FormDialog.vue";
import TakipTalebiGosterForm from "@/components/eicrapro/TakipTalebiGosterForm.vue";
import FormWrapper from "@/components/FormWrapper.vue";
import DayanakList from "@/components/eicrapro/DayanakList.vue";
import FormButton from "@/components/dialogs/FormButton.vue";
import TakipAcTebligatGideriForm from "@/components/eicrapro/TakipAcTebligatGideriForm.vue";
import TakipHazirlaEvrakEkleForm from "@/components/eicrapro/TakipHazirlaEvrakEkleForm.vue";
import {FileEntity} from "@/entity/FileEntity";
import {OdemeTipiEnum} from "@/plugins/uyap-plugin/takip_ac/OdemeTipleri";
import {TakipTalebiIndirCevap} from "@/plugins/uyap-plugin/uyap/TakipTalebiIndir";
import {saveAs} from "file-saver";
import {TamamlanmayanDosyalarRow, TamamlanmayanDosyaTurKod} from "@/plugins/uyap-plugin/uyap/TamamlanmayanDosyalar";
import TakipAcVekaletEkleForm from "@/components/eicrapro/TakipAcVekaletEkleForm.vue";
import {TamamlanmayanDosya} from "@/components/forms/form-wizard/eicrapro/TakipAcFormWizard.vue";
import {
  MtsTakipAcTaskDataInterface
} from "@/plugins/uyap-plugin/store/modules/TaskDataInterface/TakipAcTaskDataInterface";
import {ProgramPaths} from "@/enum/ProgramPaths";

@Component({
  components: {
    TakipAcVekaletEkleForm,
    TakipHazirlaEvrakEkleForm,
    TakipAcTebligatGideriForm, FormButton, DayanakList, FormWrapper, TakipTalebiGosterForm, FormDialog
  }
})
export default class MtsTakipAcFormWizard extends Mixins(ObjectInputMixin) {
  headers = [
    {text: "Dosya No", value: "dosyaNo"},
    {text: "Birim Adı", value: "birimAdi"},
    {text: "Taraflar", value: "taraflar"},
    {text: "Dosya Açılış Tarihi", value: "dosyaAcilisTarihi"}
  ]
  dosyalar: Array<MtsTamamlanmayanDosya> = [];
  secilenDosyalar = [];
  isReady = false;
  onFly = false;
  dosyaSecilmediError = false;
  vekaletCheck = true;
  vekaletCache: Array<{ hash: string, data: FileEntity }> = [];
  info = {
    show: false,
    message: ""
  }
  odemeTipiEnum = OdemeTipiEnum;

  @Watch('secilenDosyalar')
  onSecilenDosyalarChange() {
    this.dosyaSecilmediError = false;
  }

  mounted() {
    this.load();
  }

  async load() {
    this.isReady = false;
    let uyapTamamlanmayanlar = await this.$uyap.TamamlanmayanDosyalar().run({dosyaTurKod: TamamlanmayanDosyaTurKod.MTS});
    let eicraproTamamlanmayanlar = (await this.$http.get('/api/v1/uyap-takip')).data;
    let dosyalar: Array<MtsTamamlanmayanDosya> = [];
    for (let uyapTamamlanmayan of uyapTamamlanmayanlar) {
      let eicproData = eicraproTamamlanmayanlar.find((item: any) => item.uyap_dosya_id == uyapTamamlanmayan.dosyaID);
      //TODO: class olarak olştur veya TakipAcTaskDataInterface kullan
      let dosya: MtsTamamlanmayanDosya = {
        ...uyapTamamlanmayan,
        createByEicrapro: !!eicproData,
        ttDownloading: false,
        eicraproData: {
          dosya: undefined,
          dosya_esas_no: undefined,
          file: null,
          hash: undefined,
          icra_dairesi: undefined,
          id: undefined,
          status: undefined,
          takip_talebi_id: undefined,
          uyap_dosya_id: undefined,
          vekalet_id: null,
          dayanak: [],
          vekalet: null
        }
      };
      if (eicproData) {
        dosya.eicraproData = {
          ...eicproData,
          vekalet: {}
        };
      }
      dosyalar.push(dosya);
    }
    this.dosyalar = dosyalar;
    this.isReady = true;
  }

  async ttIndir(dosya: MtsTamamlanmayanDosya) {
    dosya.ttDownloading = true;
    let blobData: TakipTalebiIndirCevap = await this.$uyap.MtsTakipTalebiIndir(dosya.dosyaID).run({
      data: {},
      download: true,
      filename: "takip_talebi.udf"
    }).finally(() => (dosya.ttDownloading = false));
    saveAs(blobData.file, "Takip Talebi.udf");
  }

  // dosya kaydetme hatası düzeltilene kadar kaldırma
  waitThisLong(saniye: number) {
    return new Promise(resolve => setTimeout(resolve, saniye * 1000))
  }

  async createTask() {
    this.info = {
      show: true,
      message: "Görevler oluşturulmaya başlandı."
    }
    if (this.secilenDosyalar.length == 0) {
      this.info.show = false;
      this.dosyaSecilmediError = true;
      return;
    }
    this.info.message = "Vekaleti eksik olan dosyalar kontrol ediliyor."
    let check = true;
    this.secilenDosyalar.forEach((dosya: TamamlanmayanDosya) => {
      if (!(dosya.eicraproData.vekalet_id || dosya.eicraproData.vekalet?.dosya)) {
        check = false;
      }
    });
    this.vekaletCheck = check;
    if (!this.vekaletCheck) {
      this.info.show = false;
      return;
    } else {
      this.onFly = true;
      let tasks: Array<ITask> = [];
      let dosya: MtsTamamlanmayanDosya
      let dosyaIndex: number;
      for ([dosyaIndex, dosya] of this.secilenDosyalar.entries()) {
        if (dosya.eicraproData.vekalet?.dosya && ((dosya.eicraproData.vekalet_id && dosya.eicraproData.vekalet.hash !== dosya.eicraproData.file?.hash) || !dosya.eicraproData.vekalet_id)) {
          this.info.message = (dosyaIndex + 1) + ". dosyanın vekaleti yükleniyor."
          dosya.eicraproData.file = await this.saveVekalet(dosya.eicraproData.vekalet);
          dosya.eicraproData.vekalet_id = dosya.eicraproData.file?.id;
          delete dosya.eicraproData.vekalet.data;
        }
        let dayanaklarObject: Array<FileEntity> = [];
        for (const dayanak of dosya.eicraproData.dayanak) {
          this.info.message = (dosyaIndex + 1) + ". dosyanın " + dayanak.name + " dayanağı yükleniyor."
          // @ts-ignore
          delete dosya.eicraproData.dayanak.data;
          if (!dayanak.id) {
            let formData = new FormData();
            formData.append('aciklama', dayanak.name);
            formData.append('name', dayanak.name);
            formData.append('file', dayanak.dosya);
            formData.append('dokuman_turu_id', "9");
            formData.append('mime_type', dayanak.mimeType);
            let savedFile = await this.$http.post('/api/v1/file', formData);
            dayanaklarObject.push(savedFile.data);
            // dosya kaydetme hatası düzeltilene kadar kaldırma
            await this.waitThisLong(1);
          }
        }
        this.info.message = (dosyaIndex + 1) + ". dosyanın görevi oluşturuluyor."
        let taskData: MtsTakipAcTaskDataInterface = new MtsTakipAcTaskDataInterface();
        if (dosya.createByEicrapro) {
          taskData = {...dosya.eicraproData.dosya};
          taskData.parsedFile.vekaletObject = dosya.eicraproData.file;
          taskData.parsedFile.dayanaklarObject = dayanaklarObject;
        } else {
          // @ts-ignore
          taskData.parsedFile = {
            vekaletObject: dosya.eicraproData.file,
            dayanaklarObject: dayanaklarObject,
            hash: ""
          }
          delete dosya.eicraproData;
          delete dosya.createByEicrapro;
          taskData.dosyaBilgileri = dosya;
        }
        // @ts-ignore
        let task: ITask = new Task();
        task.birim_adi = dosya.birimAdi;
        task.dosya_id = dosya.dosyaID;
        task.task_type_id = TaskTuru.mts_takip_ac;
        task.data = taskData;
        this.info.message = (dosyaIndex + 1) + ". dosyanın görevi oluşturuldu."
        tasks.push(task);
      }
      this.info.message = "Ana görev oluşturuluyor."
      await this.$store.dispatch('postTasks', tasks);
      this.info.show = false;
      this.onFly = false;
      await this.$router.push(ProgramPaths.eicrapro + '/islemler');
    }
  }

  async saveVekalet(vakalet: { data: string, dosya: Blob, hash: string, mimeType: string, name: string }) {
    let formData = new FormData();
    formData.append('aciklama', vakalet.name);
    formData.append('name', vakalet.name);
    formData.append('file', vakalet.dosya);
    formData.append('dokuman_turu_id', '1');
    formData.append('mime_type', vakalet.mimeType);
    formData.append('hash', vakalet.hash);
    let dayanakResponse = await this.$http.post('/api/v1/file', formData);
    return dayanakResponse.data;
  }

  addVekalet(vekalet: { data: string, dosya: Blob, hash: string, mimeType: string, name: string }, dosya: MtsTamamlanmayanDosya) {
    dosya.eicraproData.vekalet = vekalet;
  }

  addAllVekalet(vekalet: { data: string, dosya: Blob, hash: string, mimeType: string, name: string }) {
    this.dosyalar.forEach((dosya: MtsTamamlanmayanDosya) => {
      if (!dosya.eicraproData.vekalet_id || !dosya.eicraproData.vekalet)
        dosya.eicraproData.vekalet = vekalet;
    });
  }
}

export interface MtsTamamlanmayanDosya extends TamamlanmayanDosyalarRow {
  createByEicrapro: boolean,
  ttDownloading: boolean,
  eicraproData: {
    id: number | undefined,
    hash: string | undefined,
    dosya: any | undefined,
    uyap_dosya_id: string | undefined,
    dosya_esas_no: string | undefined,
    icra_dairesi: string | undefined,
    vekalet_id: number | undefined | null,
    vekalet: {
      data: string, //base64 data
      dosya: Blob,
      hash: string,
      mimeType: string,
      name: string
    } | null,
    file: FileEntity | null,
    takip_talebi_id: number | undefined,
    dayanak: Array<TamamlanmayanDosyaFile>,
    status: 1 | 2 | undefined  // 1: tevzi, 2: açıldı
  }
}

interface TamamlanmayanDosyaFile extends FileEntity {
  data: string,
  dosya: Blob,
  mimeType: string
}
